<template>

<app-page :locked="is.saving || is.deleting || is.copying">

	<app-page-head :title="title" :back="$route.meta.back">

		<app-page-head-action :disabled="!isDirty" icon="undo" tooltip="Undo changes" v-on:click.native="onUndoClick" />
		<app-page-head-action :disabled="!isDirty" :loading="is.saving" icon="save" tooltip="Save changes" v-on:click.native="onSaveClick" />
		<app-page-head-action v-if="!isNew" :loading="is.deleting" :disabled="!canDelete" :danger="true" icon="delete" tooltip="Delete" v-on:click="onDeleteClick" />
		
	</app-page-head>

	<app-page-content :is-grown="true">

		<app-page-content-section :is-padded="true">

			<app-input-toggle :disabled="!isNew" v-model="model.type" :full-width="true" :options="$constants.typeName" />
			<app-input-suggest :disabled="!isNew" v-if="model.type === $constants.type.category" v-model="model.category" store="categories" image="icon" color="background" :show-all="true" label="Category" placeholder="Select category" />
			<app-input-suggest :disabled="!isNew" v-if="model.type === $constants.type.group" v-model="model.group" store="groups" image="image" label="Group" placeholder="Select group" />
			<app-input-suggest :disabled="!isNew" v-if="model.type === $constants.type.item" v-model="model.item" store="items" image="image" label="Item" placeholder="Select item" />
			<app-input-suggest :disabled="!isNew" v-if="model.type === $constants.type.location" v-model="model.location" store="locations" image="logo" label="Location" :show-all="true" placeholder="Select location" />
			<app-input-text :disabled="model.auto" :validation="$v.model.text" v-model="model.text" label="Term" placeholder="Enter term" />

		</app-page-content-section>

	</app-page-content>

	<app-page-foot />

</app-page>

</template>

<script>

import mixForm from '@/mixin/form'
import Vue from 'vue'
import slugify from 'slugify'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			model: {
				id: 0,
				auto: false,
				type: 1,
				text: '',
				group: 0,
				category: 0,
				item: 0,
				location: 0
			},
			noun: 'term',
			param: 'term'
		}

	},

	validations: {
		model: {
			text: {
				required
			}
		}
	},

	computed: {

		isNew: function() {

			return this.$route.name === 'Term.New'

		},

		canDelete: function() {

			return !this.isNew && !this.model.auto

		},

		title: function() {

			return (this.isNew) ? 'Add ' + this.noun : 'Edit ' + this.noun

		},

		parentClone: function() {

			var parent

			if (this.model.type === this.$constants.type.category) parent = this.$store.getters['categories/find'](this.model.category)
			if (this.model.type === this.$constants.type.group) parent = this.$store.getters['groups/find'](this.model.group)
			if (this.model.type === this.$constants.type.item) parent = this.$store.getters['items/find'](this.model.item)
			if (this.model.type === this.$constants.type.location) parent = this.$store.getters['locations/find'](this.model.location)

			return this.$util.clone(parent)

		},

		parentStore: function() {

			var store

			if (this.model.type === this.$constants.type.category) store = 'categories'
			if (this.model.type === this.$constants.type.group) store = 'groups'
			if (this.model.type === this.$constants.type.item) store = 'items'
			if (this.model.type === this.$constants.type.location) store = 'locations'

			return store

		}

	},

	created: function() {

		if (this.$route.params.parent) {

			this.model.type = parseInt(this.$route.params.type)

			if (this.model.type === this.$constants.type.category) this.model.category = this.$route.params.parent
			if (this.model.type === this.$constants.type.group) this.model.group = this.$route.params.parent
			if (this.model.type === this.$constants.type.item) this.model.item = this.$route.params.parent
			if (this.model.type === this.$constants.type.location) this.model.location = this.$route.params.parent

			var parent

			if (this.model.type === this.$constants.type.category) parent = this.$store.getters['categories/find'](this.model.category)
			if (this.model.type === this.$constants.type.group) parent = this.$store.getters['groups/find'](this.model.group)
			if (this.model.type === this.$constants.type.item) parent = this.$store.getters['items/find'](this.model.item)
			if (this.model.type === this.$constants.type.location) parent = this.$store.getters['locations/find'](this.model.location)

			if (this.$route.params.term === 'name') {

				this.model.auto = true
				this.model.text = slugify(parent.title, {
					replacement: ' ',
					lower: true
				})

			} else {

				this.model.text = parent.terms[this.$route.params.term - 1]

			}

		}

		Vue.set(this, 'original', this.$util.clone(this.model))

	},

	methods: {

		onSaveClick: async function() {

			this.$v.$touch()

			if (this.$v.$invalid) {

				this.is.error = true

			} else {

				this.is.saving = true

				var parent = this.parentClone

				if (this.isNew) {

					parent.terms.push(this.model.text)

				} else {

					parent.terms[this.$route.params.term - 1] = this.model.text

				}

				await this.$store.dispatch(this.parentStore + '/save', parent)

				this.goBack()

			}

		},

		onDeleteClick: async function() {

			this.is.deleting = true

			var parent = this.parentClone

			parent.terms.splice(this.$route.params.term - 1, 1)
			
			await this.$store.dispatch(this.parentStore + '/save', parent)

			this.goBack()
			

		},

		onUndoClick: function() {

			Vue.set(this, 'model', this.$util.clone(this.original))

		}

	}

}

</script>

<style scoped>

</style>
